<template>
  <div>
    <a-tree-select
      v-model="value"
      style="width: 200px"
      :tree-data="treeData"
      tree-checkable
      :treeCheckStrictly="true"
      :show-checked-strategy="SHOW_PARENT"
      :showCheckedStrategy="SHOW_CHILD"
      search-placeholder="Please select"
    >
      <a-tooltip placement="top" title="Title" slot="title" slot-scope="{ label }">
        <template slot="title">{{label}}</template>
        <span>{{label}}</span>
      </a-tooltip>
    </a-tree-select>
    <a-select
      v-model="value"
      style="width: 200px"
      :options="treeData"
      tree-checkable
      :treeCheckStrictly="true"
      :show-checked-strategy="SHOW_PARENT"
      :showCheckedStrategy="SHOW_CHILD"
      search-placeholder="Please select"
    >
      <a-tooltip placement="top" title="Title" slot="title" slot-scope="{ label }">
        <template slot="title">{{label}}</template>
        <span>{{label}}</span>
      </a-tooltip>
    </a-select>
    <a-button @click="visible = !visible">提交</a-button>
    <a-modal
      title="Title"
      v-model="visible"
      dragModal
    >
      <p>dddd</p>
    </a-modal>
<!--    <vxe-modal v-model="visible" id="myModal6" width="800" height="400" min-width="460" min-height="320" show-zoom resize remember storage transfer>-->
<!--      <template v-slot:title>-->
<!--        <span style="color: red;">完整功能的窗口（移动、拖动、状态保存）</span>-->
<!--        <span style="color: red;">通过设置 transfer 将弹框容器插入 body</span>-->
<!--      </template>-->
<!--      <div>ddd</div>-->
<!--    </vxe-modal>-->
  </div>
</template>

<script>
import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
const SHOW_CHILD = TreeSelect.SHOW_CHILD
const treeData = [
  {
    value: '0-0',
    key: '0-0',
    label: 'ddddddddddddddddddddddddd',
    scopedSlots: { title: 'title' },
    children: [
      {
        title: 'Child Node1',
        value: '0-0-0',
        key: '0-0-0'
      }
    ]
  },
  {
    title: 'Node2',
    value: '0-1',
    key: '0-1',
    children: [
      {
        title: 'Child Node3',
        value: '0-1-0',
        key: '0-1-0',
        disabled: true
      },
      {
        title: 'Child Node4',
        value: '0-1-1',
        key: '0-1-1'
      },
      {
        title: 'Child Node5',
        value: '0-1-2',
        key: '0-1-2'
      }
    ]
  }
]
export default {
  data() {
    return {
      value: ['0-0'],
      treeData,
      SHOW_PARENT,
      SHOW_CHILD,
      visible: false
    }
  }
}
</script>
